.rdp {
  --rdp-cell-size: 40px;
  --rdp-caption-font-size: 18px;
  --rdp-accent-color: #0000ff;
  /* --rdp-background-color: #e7edff; */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  --rdp-outline: none; /*2px solid var(--rdp-accent-color);*/
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  margin: 1em;
}

.rdp-months {
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.rdp-dropdown {
  > option {
    font-size: 15px;
    background-color: transparent;
  }
}
